<template>
  <b-card-code title="Lista de Fornecedores">
    <b-row>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Filtrar Por:"
          
        >
          <b-form-select
          v-model="FiltroTela.filtrarPorSelected"
          :options="filtrarPorOptions">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col
        md="6"
        xl="4"
        class="mb-1"
      >
        <b-form-group
          label="Valor:"
          
        >
          <b-form-input
            id="filtrarPorValue"
            placeholder=""
            v-model="FiltroTela.filtrarPorValue"
            required
          />
        </b-form-group>
      </b-col>

    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >
    <br>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="primary"
      type="button" @click="Consultar"><span>BUSCAR</span></b-button>
    </div>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="success"
      type="button" @click="$router.push('DevProdutoFormulario')"><span>NOVO</span></b-button>
    </div>

    </b-col>
    </b-row>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(codparc)="row">
      <b-col
        md="6"
        xl="1"
        class="mb-1"
        >
        <b-button size="sm"
         style="width: 100px;"
          variant="warning"
          @click="RedirecionaDetalhe(row.item.codparc,'FornecedorWFDetalhe')" class="mr-1">
          DETALHE
        </b-button>
        </b-col>
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
</b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'

import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BCol,
    BButton,
  },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'FornecedorWF',
        Selecionado: false,
        filtrarPorValue: '',
        filtrarPorSelected: 'CODPARC',
        FiltroComplementar: [],
      },
      filtrarPorOptions: [
        { value: 'CODPARC', text: 'Código' },
        { value: 'NOMEPARC', text: 'Nome' },
        { value: 'RAZAOSOCIAL', text: 'Razão Social' },
        { value: 'DESCRTIPPARC', text: 'Tipo Parceiro' },
      ],
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'codparc', label: 'AÇÕES' },
        { key: 'seq', label: 'Cod. PARCEIRO', sortable: true },
        { key: 'nomeparc', label: 'Nome', sortable: true },
        { key: 'razaosocial', label: 'Razao Social', sortable: true },
        { key: 'descrtipparc', label: 'Tipo Parceiro', sortable: true },
        { key: 'ativo', label: 'Ativo', sortable: true },
      ],
      /* eslint-disable global-require */
      items: [
      ],
    }
  },
  mounted() {
    // Seta q quantidade de Linhas no Grid
    this.totalRows = this.items.length
    // Consulta Dados para Filtro na Tela
    this.DescarregarFiltroTela()
  },
  methods: {
    async Consultar() {
      Spinner.StarLoad()
      let notification = ''
      const usuario = this.$session.get('usuario')
      const URLpost = `/skParceiroWF/ListParceiroWF?CODUSU=${usuario.codusu}&MODULO=FORNECEDOR`

      this.CarregaFiltroTela()
      const ParametroJson = JSON.parse(JSON.stringify(this.FiltroTela))
      await apiPortalSky.post(URLpost, ParametroJson, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => {
        if (response.status === 200) {
          this.items = response.data
          this.totalRows = this.items.length
        } else {
          notification = ConexaoAPI.MsgFiltroSemRetorno()
          AlertaMsg.FormataMsg(notification)
        }
      })
        .catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    RedirecionaDetalhe(id, rota) {
      const $rota = `${rota}/${id}`
      this.$router.push($rota)
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    DescarregarFiltroTela() {
      const usuario = this.$session.get('usuario')
      const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (Filtrado.Selecionado) {
        // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
        if (Filtrado.filtrarPorValue !== '') {
          this.FiltroTela = Filtrado// Carrega o Filtro

          // Descarrega Filtro Complementar
          //this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
          //  switch (ftComplementar.parametro) {
          //    case "":
          //      break
          //     
          //  }

          this.Consultar() // Executa o Filtro
        }
      }
    },
  },
  computed: {
  },
}
</script>
